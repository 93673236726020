


































































import { SfButton } from '@storefront-ui/vue';
import { computed, defineComponent, onMounted, useContext } from '@nuxtjs/composition-api';
import { useMyAccountNavigation } from '~/bbrTheme/modules/customer/stores/myAccountNavigation';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'MyProfile',
  components: {
    SfButton,
  },
  middleware: 'is-authenticated',
  setup() {
    const { localePath, i18n } = useContext();
    const store = useMyAccountNavigation();
    const customerStore = useCustomerStore();
    const {
      firstName,
      lastName,
      email,
      prefix,
    } = storeToRefs(customerStore);

    onMounted(() => {
      store.setBreadcrumbs([
        {
          text: i18n.t('Profile') as string,
          link: localePath({ name: 'customer-my-profile' }),
        }
      ]);
    });

    return {
      prefix,
      firstName,
      lastName,
      email,
    }
  },
});
